.navbar {
  background: linear-gradient(90deg, rgb(0 0 0) 0%, rgb(94 94 94) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 20px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #6d76f7;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media (max-width: 960px) {
  .navbar {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #6668f4;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #7577fa;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #4ad9e4;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
  }

  Button {
    display: none;
  }
}
.logo-fix {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}
.nav-logo {
  max-width: 70%;
  height: auto;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: linear-gradient(90deg, rgb(0 0 0) 0%, rgb(94 94 94) 100%);
  color: #fff;
  z-index: 400;
  margin-left: -250px;
  position: absolute;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #6d7fcc;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

/* .menu-open {
  background: #6d7fcc;
} */

.nav-item:hover {
  color: #ffffff;
  background: #fff;
}

.items-menu {
  color: #ffffff;
  background: #c2b5b522;
}
li .a {
  display: flex;
  gap: 10px;
  align-items: center;
}

li a.dropdown-toggle::after {
  /* display: inline-flex;
  position: relative; */
  /* left: 60%; */
  /* top: 10%; */
  /* display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center; */
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466b3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3a4c99;
}

.sidebar-item-style {
  color: #f1cd55;
  width: 85%;
  border: 2px solid;
  height: 60px;
  margin: 2rem auto;
  display: flex;
  padding: 0 30px;
  background: #000000;
  box-sizing: border-box;
  transition: background-color 0.2s, color 0.2s;
  align-items: center;
  border-color: #f1cd55;
  border-radius: 78.8418px;
  justify-content: space-evenly;
}
.item-style {
  font-size: 25px;
  text-align: left;
  padding-left: 3rem;
}
.nav-link{
  color: #fff;
}