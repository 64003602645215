.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	left:20px;
	/* background-color:#25d366;
	color:#FFF;
	border-radius:50px; */
	text-align:center;
  font-size:30px;
	/* box-shadow: 2px 2px 3px #999; */
  z-index:4000;
}

.my-float{
	margin-top:16px;
}
