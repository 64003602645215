.footer-section {
  background: #474747;
  height: fit-content;
  padding: 2.5rem 50px 1rem;
  position: relative;
}
@media screen and (max-width: 680px) {
  .footer-section {
    padding: 2.2rem 1rem 1rem;
  }
}
.mobile-section {
  display: none;
}

@media screen and (max-width: 680px) {
  .mobile-section {
    display: flex;
    justify-content: space-between;
  }
}

.footer-content {
  display: flex;
  flex-direction: column;
}
.web-section {
  display: block;
}
@media screen and (max-width: 680px) {
  .footer-content {
    align-items: center;
    margin-bottom: 2rem;
  }
  .web-section {
    display: none;
  }
}

.relevant-link {
  align-items: flex-start !important;
}
.moira-white-logo {
  width: 300px;
  height: 37px;
}
@media screen and (max-width: 680px) {
  .moira-white-logo {
    height: 40px;
  }
}
.content-header {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: #ffffff;
  display: flex
}
@media screen and (max-width: 680px) {
  .content-header {
    font-size: 20px;
  }
}

.content {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 1rem;
}
.link-section {
  text-align: left !important;
}
@media screen and (max-width: 680px) {
  .content {
    text-align: center;
    font-size: 12px !important;
  }
  .qoruz-brief {
    text-align: center;
    width: 304px;
  }
}
.social-media-section {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 0.9rem;
  cursor: pointer;
}
.relevant-links {
  cursor: pointer;
  width: fit-content;
}

.email-section {
  margin-top: 1rem;
  width: fit-content;
  position: relative;
}
.email-field {
  width: 324px;
  height: 48px;
  border: none;
  background: #ffffff;
  border-radius: 30px;
  padding: 0px 6px 0px 24px;
  position: relative;
}
::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #474747;
  padding-left: 24px;
}
.email-submit {
  width: 94px;
  height: 36px;
  background: linear-gradient(272.1deg, #f35627 3.01%, #ff7c44 99.27%);
  box-shadow: 0px 12px 20px -5px rgba(245, 92, 45, 0.07);
  border-radius: 26px;
  position: absolute;
  right: 6px;
  top: 6px;
  margin: 0;
  border: none;
}
.email-icon {
  position: absolute;
  left: 24px;
  top: 16px;
}

.contact-section {
  margin-top: 2.2rem;
  color: #ffffff;
  font-style: normal;
}

.mobile {
  display: none;
  margin: 0 0 2.2rem;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 680px) {
  .web-contact {
    display: none;
  }
  .mobile {
    display: flex;
  }
}
.contact-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
}
.contact-details-section {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  width: fit-content;
}
@media screen and (max-width: 680px) {
  .contact-details-section {
    flex-direction: row !important;
    gap: 2rem;
  }
}
.contact-details {
  display: flex;
  align-items: center;
  gap: 9px;
  text-decoration: none !important;
  color: #ffffff !important;
}
.contact-content {
  display: flex;
  flex-direction: column;
}
.contact-name {
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  line-height: 12px;
}

.contact-number {
  font-weight: 700;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
}
.copy-right-text {
  margin-top: 20px;
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.blob-footer {
  position: absolute;
  bottom: 0px;
  right: 0;
}
@media screen and (max-width: 680px) {
  .blob-footer {
    display: none;
  }
}

.blob-footer-mobile {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
}

@media screen and (max-width: 680px) {
  .blob-footer-mobile {
    display: block;
  }
}
