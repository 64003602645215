@media screen and (max-width: 748px) {
  .desktop {
   display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}

.about-description {
  padding: 2rem;
  background-color: rgb(246, 242, 250);
}

.gradient {
  color: rgb(0, 0, 0);
}