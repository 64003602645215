.spacilities-content {
  font-size: 22px;
  color: #696969;
}

.divided-line {
  height: 2px !important;
  color: black !important;
}

.arched .li {
  font-size: 20px;
  color: #696969;
}

@media screen and (max-width: 680px) {
  .mobile-arched-content {
    padding: 1rem !important;
  }
}
