.bay-section {
  padding: 5rem 10rem;
}

@media screen and (max-width: 768px) {
  .bay-section {
    padding: 2rem 2rem;
  }
}

.production-header-section {
  font-size: 26px;
  color: #696969;
}
