.combination-section {
  padding: 5rem 10rem;
}

@media screen and (max-width: 768px) {
  .combination-section {
    padding: 2rem 2rem;
  }
}

.divided-line {
  height: 2px !important;
  color: blue !important;
}

.combination-images {
  display: flex;
  gap: 10px;
  margin: 2rem 0;
}
@media screen and (max-width: 768px) {
  .combination-images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 2rem 0;
  }
}
