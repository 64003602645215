
.intro {
    position: relative;
    height: 65vh;
    text-align: center;
    text-shadow: 1px 1px;
    color: #fff;
    background-image: url("./assets/company.jpg");
}
    
    /* @media (min-width: "512px") {
        height: 55vh;
    } */

    .image-overlay {
        display: block;
        position: absolute;
        bottom: 0;
        opacity: 0.3;
        background-color: #000;
        width: 100%;
        height: 100%;
        top: 0;
    }
    .squares-wrapper{
        /* background: #4e54c8;
        background: linear-gradient(to left, #3182d5, #4e54c8); */
        width: 100%;
        height:100%;
    }

    .squares{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
        .li-style{
            position: absolute;
            display: block;
            list-style: none;
            width: 20px;
            height: 20px;
            background: rgba(255, 255, 255, 0.2);
            animation: animate 25s linear infinite;
            bottom: -150px;            
        }

.quote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 200%;
    overflow: hidden;
    width: 100vw;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    z-index : 1;
}
    h1 {
        margin-top: 0;
        font-size: 1.5rem;
        @media (min-width: "+768px") {
            font-size: 3rem;
        }
    
}

@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}