
  .container{
    padding: 3rem 0;
  }
  
  .container .card{
    position: relative;
    cursor: pointer;
  }
  
  .container .card .face{
    width: 300px;
    height: 200px;
    transition: 0.5s;
  }
  
  .container .card .face.face1{
    position: relative;
    color: black;
    background: rgb(180, 173, 173);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
  }
  
  .container .card:hover .face.face1{
    background: #817f7ed1;
    color: white;
    transform: translateY(0);
    display: flex;
    flex-direction: column;
  }
  
  .container .card .face.face1 .content{
    opacity: 0.6;
    transition: 0.5s;
  }
  
  .container .card:hover .face.face1 .content{
    opacity: 1;
  }
  
  .container .card .face.face1 .content img{
    max-width: 225px;
  }
  
  .container .card .face.face1 .content h3{
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 1.5em;
  }
  
  .container .card .face.face2{
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(-100px);
  }
  
  .container .card:hover .face.face2{
    transform: translateY(0);
  }
  
  .container .card .face.face2 .content p{
    margin: 0;
    padding: 0;
  }
  
  .container .card .face.face2 .content a{
    margin: 15px 0 0;
    display:  inline-block;
    text-decoration: none;
    font-weight: 900;
    color: #333;
    padding: 5px;
    border: 1px solid #333;
  }
  
  .container .card .face.face2 .content a:hover{
    background: #333;
    color: #fff;
  }

  .card-section {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .card-header {
    font-size: 16px;
    font-family: normal;
    font-weight: 600;
  }

  .layout-text {
    color: #333 !important;
  }
  @media screen and (max-width: 680px){
    .layout-text {
      font-size: 14px !important;
    }
  }
  .layout-content-header {
    margin-top: 0 !important;
  }