.carousel-image-section {
  right: 0 !important;
  padding-bottom: 0 !important;
  background-color: #f6f2fa;
  color: black;
  /* width: fit-content; */
  /* border-radius: 8px; */
  padding: 12px;
  opacity: 0.6;
  font-style: normal;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
}
/* .stokes {
	text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF;
} */
@media screen and (max-width: 768px) {
  .carousel-image-section {
    font-size: 14px;
  }
}
