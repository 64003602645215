.accessories {
    margin-top: 2rem;
    font-size: 22px;
    color: black;
    font-family: normal;
    font-weight: 900;
}

.accessories-img {
    width: 400px;
    height: 300px;
}
@media screen and (max-width: 768px){
    .accessories-img {
        width: 300px;
        height: 300px;
    }
}