#inner {
    grid-row: 2;
    grid-column: 2;
    align-self: center;
    justify-self: center;
    padding: 2rem;
    background-color: rgb(246, 242, 250);
}

#illustration {
    grid-row: 3;
    grid-column: 2;
    align-self: center;
    justify-self: center;
    width: 100%;
    padding: 10px;
}

@media screen and (min-width: 768px) {
    #home-container {
        display: grid;
        /* grid-template-columns: 0.5fr 1fr 1fr 0.5fr; */
        grid-template-rows: 1fr;
        grid-gap: 20px;
        margin-top: 8rem;
        margin-bottom: 1rem;
    }
    #inner {
        grid-column: 2;
        grid-row: 1;
        margin: 0 3rem;
    }

    #illustration {
        grid-column: 3;
        grid-row: 1;
    }
}
@media screen and (max-width: 748px) {
    #home-container {
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
        grid-template-rows: 1fr;
        padding-top: 30px;
    }
    #inner{
    align-content: center;
    justify-content: center;
    align-items: center;
    grid-row: 2;
}
}
img#logo {
    width: 130px;
    margin-bottom: 1.6em;
}

h1 {
    text-transform: uppercase;
    color: #535353;
    margin-bottom: 0.2em;
}

p.subtitle {
    font-size: 1.4em;
    color: #858585;
    margin-top: 0;
}

.cta {
    background-color: #09bbe9;
    padding: 1em;
    color: #fff;
    font-weight: bold;
    margin-top: 2em;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0 0 0 0 rgba(9, 187, 233, .2);
}

.cta:hover,
.cta:focus {
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.50, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.50, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.50, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.50, 0, 0, 1);
}

@-webkit-keyframes pulse {to {box-shadow: 0 0 0 20px rgba(9, 187, 233, 0);}}
@-moz-keyframes pulse {to {box-shadow: 0 0 0 20px rgba(9, 187, 233, 0);}}
@-ms-keyframes pulse {to {box-shadow: 0 0 0 20px rgba(9, 187, 233, 0);}}
@keyframes pulse {to {box-shadow: 0 0 0 20px rgba(9, 187, 233, 0);}}

#monitor {
    animation: monitorIn 1s ease-in-out forwards; /* assign animation to monitor with 'monitorIn' as keyframe name */
    opacity: 0;
    width: 99%;
}

.crypto-icons {
    position: absolute;
    width: 12%;
}

.crypto-icons path.b {
  fill: red;
}

#dash {
    margin-top: -4%;
    margin-left: 9%;
    animation: chatblips 0.5s ease-in-out 2.7s forwards; /* assign animation to dash with 'chatblips' as keyframe name */
    opacity: 0;
    z-index: 2;
}

#eth {
    margin-top: 9.5%;
    margin-left: 18%;
    animation: chatblips 0.5s ease-in-out 1.9s forwards; /* assign animation to eth with 'chatblips' as keyframe name */
    opacity: 0;
    z-index: 2;
}

#iota {
    margin-top: 5%;
    margin-left: -1.5%;
    animation: chatblips 0.5s ease-in-out 0.8s forwards; /* assign animation to iota with 'chatblips' as keyframe name */
    opacity: 0;
    z-index: 2;
}

@keyframes monitorIn {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes chatblips {
    /* taken from http://angrytools.com/css/animation/ for bounce-in animation*/
    0% {
        opacity: 0;
        transform: scale(0.3);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    70% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}